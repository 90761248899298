import { OccupationTiss } from '../tables/accreditation/occupation-tiss';
import { ProcedureOccupation } from './procedure';
import { Speciality } from '../tables/accreditation/speciality';

export class OccupationAccreditation {
    id: number;
    code: string;
    specialityList: Speciality[];
    procedureList: ProcedureOccupation[];
    occupationTissList: OccupationTiss[];
}