<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div fxLayoutAlign="end end" fxHide fxShow.gt-xs style="color: white;" class="p-8">
        V 0.0.1p
    </div>

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs class="pl-0">
        <!-- <img src="assets/images/logos/focus.png"> -->

        <div fxLayout="row">

            <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
                <img *ngIf="isDecember" src="assets/images/logos/clinic_all_natal.png" alt="Logo de Natal">
                <img *ngIf="!isDecember" src="assets/images/logos/clinic_all.png" alt="Logo Normal">

            </div>

        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Seja bem-vindo ao ClinicAll Web!
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            SISTEMA DE GESTÃO NA ÁREA DE SAÚDE
        </div>

       
        <div *ngIf="isDecember" style="color: rgb(175, 49, 49);" class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <b>Agradecemos por vocês estarem conosco dia após dia. Boas festas! </b>
        </div>

        <div *ngIf="data === '2024-10-18'" style="color: rgb(175, 49, 49);" class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <b>Neste dia, celebramos a dedicação, o cuidado e a compaixão de todos os profissionais que transformam vidas com seu conhecimento e humanidade. Obrigado por fazerem a diferença todos os dias! </b> <br>
            <b>Feliz dia dos Médicos! </b>
        </div>

        <div *ngIf="data === '2024-08-27'" style="color: rgb(175, 49, 49);" class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <b>Neste dia, celebramos a importância de ouvir, compreender e apoiar. Obrigado a todos os profissionais que iluminam caminhos e ajudam a construir mentes mais saudáveis e felizes! </b> <br>
            <b>Feliz dia dos Psicólogos! </b>
        </div>

        <div *ngIf="data === '2024-10-13'" style="color: rgb(175, 49, 49);" class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <b>Neste dia, homenageamos aqueles que, com dedicação e conhecimento, ajudam a restaurar movimentos e a trazer qualidade de vida. Obrigado por sua paixão em cuidar e transformar vidas! </b> <br>
            <b>Feliz dia dos Fisioterapeutas! </b>
        </div>


        <div class="link" [@animate]="{value:'*',params:{delay:'150ms',y:'25px'}}">
            <a href="http://focusts.com.br/home" target="_blank">Clique aqui para acessar o nosso site.</a>
        </div>
    </div>

    <!-- <div fxHide fxShow.gt-xs fxLayoutAlign="end end">
        <img style=" max-width: 75%; max-height: 75%; " class="p-8" src="assets/images/logos/focus.png">
    </div> -->

    <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
        <div id="login-form" autocomplete="new-password">

            <div *ngIf="eartefact.content" class="animate-logo" style="max-width: 100%;">
                <img style="max-width: 100%;" [src]="eartefact.content" alt="Minha Imagem">
            </div>

            <div class="title">Entre com sua conta</div>
            <form name="loginForm" [formGroup]="loginForm" novalidate autocomplete="off">
                <mat-form-field appearance="outline">
                    <mat-label>Usuário</mat-label>
                    <input matInput formControlName="login" oninput="this.value = this.value.toLowerCase()"
                        pattern="[a-z0-9._%+-]{4,20}" autocomplete="new-password" required id="user_login" autofocus>
                    <mat-icon matSuffix class="secondary-text">person</mat-icon>
                    <mat-error *ngIf="loginForm.get('login').hasError('required')">
                        Usuário é obrigatório
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('login').hasError('pattern')">
                        Campo usuário deve conter de 4 a 30 caracteres
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="loginForm.get('login').value == 'admin'" appearance="outline">
                    <mat-label>Usuário RTV</mat-label>
                    <input matInput formControlName="userRtv" pattern="[a-z0-9._%+-]{4,20}" autocomplete="new-password" required id="user_login" autofocus>
                    <mat-icon matSuffix class="secondary-text">person</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input class="pw-mask pb-0" matInput type="text" formControlName="password"
                        autocomplete="new-password" required>
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="loginForm.get('password').hasError('required')">
                        Senha é obrigatória
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
                    <a class="forgot-password" [routerLink]="'/auth/esqueci-minha-senha'">
                        Esqueceu a senha?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" (click)="login()"
                    [disabled]="loginForm.invalid">
                    Entrar
                </button>

            </form>

            <div class="separator">
                <span class="text">OU</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
                <button mat-raised-button class="certificado" (click)="loginDigital()">
                    Certificado Digital
                </button>
            </div>
        </div>
    </div>
</div>