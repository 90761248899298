
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway, Professional } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { Environment } from 'app/decorators/environment.decorator';

@Injectable()
export class ProfessionalEditProvider implements Resolve<any>
{

    @Environment('baseUrl') baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    professional: Professional;

    countryValue: any;
    country = { id: "" }

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.getCountry();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getProfessional()]).then(
                () => {
                    sessionStorage.setItem('navigation', location.pathname.toString());
                    resolve();
                },
                reject
            );
        });
    }

    getProfessional(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.professional = data;
                this.onDataChanged.next(this.professional);
                resolve(this.professional);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw.get('professional/wAll/' + this.routeParams.id).subscribe((res: Professional) => {
                    this.professional = res;
                    this.onDataChanged.next(this.professional);
                    resolve(res);
                }, reject);
            };
        });
    }

    getCountry() {

        this.getParameterCountry().then((value) => {

            value.forEach((country) => {

                if (country.group == 'Gerais') {
                    country.parameterList.forEach((parameter) => {

                        if (parameter.id == 28) {
                            this.countryValue = parameter.value;

                        }
                    })

                }
            })
        })

    }

    getParameterCountry(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get('parameter/context/SYSTEM')
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    insert(professional): Promise<any> {

        this.country.id = this.countryValue

        if (professional.person.address) {
            professional.person.address.country = this.country
        }

        if (professional.person.address && professional.person.address.country != null && this.country.id != "") {
            return new Promise((resolve, reject) => {
                this.gw.post('professional/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.post('professional/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        }
    }

    insertCompanyPerformerCustom(data: any) {

        return new Promise((resolve, reject) => {
            this.gw.post('professional/companyPerformerCustom', data)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },
                    error(err) {
                        reject(err);
                    },
                });
        });
    }
    deleteCompanyPerformerCustom(id: any) {

        return new Promise((resolve, reject) => {
            this.gw.delete(`professional/companyPerformerCustom/${id}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },
                    error(err) {
                        reject(err);
                    },
                });
        });
    }

    insertSolicQuick(professional): Promise<any> {

        this.country.id = this.countryValue

        if (professional.person.address) {
            professional.person.address.country = this.country
        }

        if (professional.person.address && professional.person.address.country != null && this.country.id != "") {
            return new Promise((resolve, reject) => {
                this.gw.post('professional/quick/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.post('professional/quick/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        }
    }

    edit(professional): Promise<any> {

        this.country.id = this.countryValue
        if (professional.person.address) {
            professional.person.address.country = this.country
        }

        if (professional.person.address && professional.person.address.country != null && this.country.id != "") {
            return new Promise((resolve, reject) => {
                this.gw.put('professional/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.put('professional/', professional).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        }
    }

    delete(professional): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/', professional).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    };

    uploadImage(file: File, professionalId, main, description, type: number): Promise<any> {
        return new Promise((resolve, reject) => {

            const endpoint = this.baseUrl + 'professional/' + professionalId + '/uploadImage/main/' + main + '/description/' + description + '/type/' + type;
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers }).subscribe(response => {
                resolve(response)
            }, reject);
        })
    };


    deleteImage(personImageId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/image/' + personImageId).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    };

    checkLastModifications(): Professional {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    };

    uploadCertification(file: File, uploadCertiticateTO): Promise<any> {
        return new Promise((resolve, reject) => {


            const endpoint = this.baseUrl + 'professional/upload-certification';
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            const paramsBlob = new Blob([JSON.stringify(uploadCertiticateTO)], { type: 'application/json' });

            formData.append('params', paramsBlob);

            let authToken = localStorage.getItem('token');
            let headers = new HttpHeaders();
            headers = headers.append('X-Auth-Token', authToken);

            this.httpClient.post(endpoint, formData, { headers: headers })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err)
                    }
                })
        })
    };
    getCertification(professionalId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/upload-certification/:professionalId', { professionalId: professionalId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err)
                }
            })
        });
    }
    deleteCertification(professionalId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('professional/certification/:professionalId', { professionalId: professionalId }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err)
                }
            })
        });
    }
}
