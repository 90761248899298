<div style="vertical-align: middle;" class="mb-20">
    <button mat-stroked-button style="border-radius: 7px; width: 100%;" *ngIf="statusFilter === 'new'"
        [matMenuTriggerFor]="menu" #menuTrigger>
        Adicionar Filtro
        <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #menu="matMenu">

        <button mat-menu-item *ngFor="let item of filterTypes" (click)="createForm(item)">{{ item.alias }}</button>


    </mat-menu>
</div>


<h3 *ngIf="statusFilter === 'edit'">Filtros</h3>
<div fxLayout="column" fxLayoutGap="20">
    <form [formGroup]="statusFilterForm" *ngIf="statusFilterForm" fxLayout="row" class="mb-20"
        style="align-items: baseline">

        <mat-radio-group fxLayoutGap="10" formControlName="status">
            <mat-radio-button class="mb-12" value="1">Aberta</mat-radio-button>

            <mat-radio-button value="3"><span style="color: #4f4a4a">Normal</span></mat-radio-button>
            <mat-radio-button value="4"><span style="color: #146776">Bloqueado</span></mat-radio-button>
            <mat-radio-button value="5"><span style="color: #d56a6a">Pendente</span></mat-radio-button>
            <mat-radio-button value="6"><span>Todas</span></mat-radio-button>
        </mat-radio-group>

    </form>

    <form [formGroup]="glossStatusFilterForm" *ngIf="glossStatusFilterForm" fxLayout="row" class="mb-20"
        style="align-items: baseline">

        <mat-radio-group fxLayoutGap="10" formControlName="status">
            <mat-radio-button value="N"><span style="color: #4f4a4a">Não Recursadas</span></mat-radio-button>
            <mat-radio-button value="R"><span style="color: #0a4e5a">Recursadas</span></mat-radio-button>
            <mat-radio-button value="T"><span style="color: #0a6437">Todas</span></mat-radio-button>
        </mat-radio-group>

    </form>


    <form [formGroup]="rangeFilterForm" *ngIf="rangeFilterForm" fxLayout="row" style="align-items: baseline">

        <mat-form-field appearance="outline" fxFlex="90">
            <mat-label>Período</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Início">
                <input matEndDate formControlName="end" placeholder="Fim">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-form-field>
        <mat-icon (click)="removeFilter('period')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>


    <form [formGroup]="patientFilterForm" *ngIf="patientFilterForm" fxLayout="row" style="align-items: baseline">

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Paciente</mat-label>
            <input matInput [matAutocomplete]="auto" formControlName="patient" placeholder="Paciente" type="search">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayPatientFn">
                <mat-option *ngFor="let patient of patientList" [value]="patient">
                    {{patient?.person.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-icon (click)="removeFilter('patient')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>

    <form [formGroup]="groupFilterForm" *ngIf="groupFilterForm" fxLayout="row" style="align-items: baseline">

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Grupo</mat-label>
            <input matInput [matAutocomplete]="autoGroup" formControlName="group" placeholder="Grupo" type="search">
            <mat-autocomplete autoActiveFirstOption #autoGroup="matAutocomplete" [displayWith]="displaySimpleFn">
                <mat-option *ngFor="let group of groupList" [value]="group">
                    {{group?.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-icon (click)="removeFilter('group')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>


    <form [formGroup]="insuranceFilterForm" *ngIf="insuranceFilterForm" fxLayout="row" style="align-items: baseline">

        <div fxLayout="column wrap" fxFlex="100">
            <mat-chip-list #chipList>
                <ng-container *ngFor="let select of insuranceSelectd">
                    <mat-chip>
                        <span style="width: 50px !important;" class="text-truncate"
                            matTooltip="{{select.element.name}}">
                            {{ select.element.name }}
                        </span>
                        <span>
                            <mat-icon class="mat-chip-remove" *ngIf="statusFilter === 'new'"
                                (click)="removeChip(select, 'INSURANCE')">cancel</mat-icon>
                        </span>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>


            <mat-form-field fxFlex="100" appearance="outline">
                <input matInput [matAutocomplete]="autoInsurance" [formControl]="insuranceControl"
                    placeholder="Convênio" type="search">
                <mat-icon (click)="removeFilter('insurance')" matSuffix *ngIf="statusFilter === 'new'"
                    style="cursor:pointer" matTooltip="Remover Filtro" color="warn"
                    class='material-icons'>remove</mat-icon>
            </mat-form-field>

            <mat-autocomplete #autoInsurance="matAutocomplete" [displayWith]="displayInsuranceFn">
                <mat-option *ngFor="let data of rawDataInsurance">
                    <div (click)="optionClicked($event, data,'INSURANCE')">
                        <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data,'INSURANCE')"
                            (click)="$event.stopPropagation()">
                            {{ data.element.name }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>


    </form>

    <form [formGroup]="administratorFilterForm" *ngIf="administratorFilterForm" fxLayout="row"
        style="align-items: baseline">

        <div fxLayout="column wrap" fxFlex="100">
            <mat-chip-list #chipList>
                <ng-container *ngFor="let select of administratorSelectd">
                    <mat-chip>
                        <span style="width: 50px !important;" class="text-truncate"
                            matTooltip="{{select?.element.name}}">
                            {{ select.element?.person.name }}

                        </span>
                        <span>
                            <mat-icon class="mat-chip-remove"
                                (click)="removeChip(select, 'ADMINISTRATOR')">cancel</mat-icon>
                        </span>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>


            <mat-form-field fxFlex="100" appearance="outline">
                <input matInput [matAutocomplete]="autoAdministrator" [formControl]="administratorControl"
                    placeholder="Administrador" type="search">
                <mat-icon (click)="removeFilter('administrator')" matSuffix *ngIf="statusFilter === 'new'"
                    style="cursor:pointer" matTooltip="Remover Filtro" color="warn"
                    class='material-icons'>remove</mat-icon>
            </mat-form-field>

            <mat-autocomplete #autoAdministrator="matAutocomplete" [displayWith]="displayInsuranceFn">
                <mat-option *ngFor="let data of rawDataAdministrator">
                    <div (click)="optionClicked($event, data, 'ADMINISTRATOR')">
                        <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data,'ADMINISTRATOR')"
                            (click)="$event.stopPropagation()">
                            {{ data.element?.person?.name }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>


    </form>

    <form [formGroup]="specialityFilterForm" *ngIf="specialityFilterForm" fxLayout="row" style="align-items: baseline">

        <div fxLayout="column wrap" fxFlex="100">
            <mat-chip-list #chipList>
                <ng-container *ngFor="let select of specialitySelectd">
                    <mat-chip>
                        <span style="width: 50px !important;" class="text-truncate"
                            matTooltip="{{select.element?.person.name}}">
                            {{ select.element?.person.name }}
                        </span>
                        <span>
                            <mat-icon class="mat-chip-remove"
                                (click)="removeChip(select,'SPECIALITY')">cancel</mat-icon>
                        </span>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>


            <mat-form-field fxFlex="100" appearance="outline">
                <input matInput [matAutocomplete]="autoSpeciality" [formControl]="specialityControl"
                    placeholder="Especialista" type="search">
                <mat-icon (click)="removeFilter('speciality')" matSuffix *ngIf="statusFilter === 'new'"
                    style="cursor:pointer" matTooltip="Remover Filtro" color="warn"
                    class='material-icons'>remove</mat-icon>

            </mat-form-field>

            <mat-autocomplete #autoSpeciality="matAutocomplete" [displayWith]="displaySpecialityFn">
                <mat-option *ngFor="let data of rawDataSpeciality">
                    <div (click)="optionClicked($event, data, 'SPECIALITY')">
                        <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data, 'SPECIALITY')"
                            (click)="$event.stopPropagation()">
                            {{ data.element?.person.name }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>

    </form>

    <form [formGroup]="professionalFilterForm" *ngIf="professionalFilterForm" fxLayout="row"
        style="align-items: baseline">

        <div fxLayout="column wrap" fxFlex="100">
            <mat-chip-list #chipList>
                <ng-container *ngFor="let select of professionalSelectd">
                    <mat-chip>
                        <span style="width: 50px !important;" class="text-truncate"
                            matTooltip="{{select.element?.person.name}}">
                            {{ select.element?.person.name }}
                        </span>
                        <span>
                            <mat-icon class="mat-chip-remove"
                                (click)="removeChip(select,'PROFESSIONAL')">cancel</mat-icon>
                        </span>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>


            <mat-form-field fxFlex="100" appearance="outline">
                <input matInput [matAutocomplete]="autoSpeciality" [formControl]="professionalControl"
                    placeholder="Profissional" type="search">
                <mat-icon (click)="removeFilter('professional')" matSuffix *ngIf="statusFilter === 'new'"
                    style="cursor:pointer" matTooltip="Remover Filtro" color="warn"
                    class='material-icons'>remove</mat-icon>

            </mat-form-field>

            <mat-autocomplete #autoSpeciality="matAutocomplete" [displayWith]="displaySpecialityFn">
                <mat-option *ngFor="let data of rawDataProfessional">
                    <div (click)="optionClicked($event, data, 'SPECIALITY')">
                        <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data, 'PROFESSIONAL')"
                            (click)="$event.stopPropagation()">
                            {{ data.element?.person.name }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>

    </form>

    <form [formGroup]="batchEntryFilterForm" *ngIf="batchEntryFilterForm" fxLayout="row" style="align-items: baseline">

        <div fxLayout="column wrap" fxFlex="100">
            <mat-chip-list #chipList>
                <ng-container *ngFor="let select of batchEntrySelectd">
                    <mat-chip>
                        <span style="width: 50px !important;" class="text-truncate"
                            matTooltip="{{select.element?.number}}">
                            {{ select.element?.number }}
                        </span>
                        <span>
                            <mat-icon class="mat-chip-remove"
                                (click)="removeChip(select,'BATCHENTRY')">cancel</mat-icon>
                        </span>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>


            <mat-form-field fxFlex="100" appearance="outline">
                <input matInput [matAutocomplete]="autoBatchEntry" [formControl]="batchEntryControl"
                    placeholder="Lançamento em Lote" type="search">
                <mat-icon (click)="removeFilter('batchEntry')" matSuffix *ngIf="statusFilter === 'new'"
                    style="cursor:pointer" matTooltip="Remover Filtro" color="warn"
                    class='material-icons'>remove</mat-icon>

            </mat-form-field>

            <mat-autocomplete #autoBatchEntry="matAutocomplete" [displayWith]="displayBatchEntryFn">
                <mat-option *ngFor="let data of rawDataBatchEntry">
                    <div (click)="optionClicked($event, data, 'BATCHENTRY')">
                        <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data, 'BATCHENTRY')"
                            (click)="$event.stopPropagation()">
                            {{ data.element?.number }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>

    </form>

    <form [formGroup]="dateFilterForm" *ngIf="dateFilterForm" style="align-items: baseline">

        <mat-form-field appearance="outline" fxFlex="55">
            <mat-label>Data Limite</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-icon (click)="removeFilter('dateLimit')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>

    <form [formGroup]="typeGuideForm" *ngIf="typeGuideForm">

        <mat-form-field fxFlex="100" appearance="outline">
            <mat-label>Tipo de Faturamento TISS</mat-label>
            <mat-select formControlName="typeGuide">
                <mat-option [value]="null">
                    Selecione
                </mat-option>
                <mat-option *ngFor="let typeGuide of typeGuideList" [value]="typeGuide">
                    {{typeGuide.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon (click)="removeFilter('typeGuide')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>

    <form [formGroup]="statusAuthorizationForm" *ngIf="statusAuthorizationForm">

        <mat-form-field fxFlex="100" appearance="outline">
            <mat-label>Status Autorização</mat-label>
            <mat-select formControlName="status">
                <mat-option [value]="null">
                    Selecione
                </mat-option>
                <mat-option *ngFor="let status of autorizationStatus" [value]="status">
                    {{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon (click)="removeFilter('statusAuthorization')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>
    <form [formGroup]="statusPatientForm" *ngIf="statusPatientForm">

        <mat-form-field fxFlex="100" appearance="outline">
            <mat-label>Status Paciente</mat-label>
            <mat-select formControlName="status" [compareWith]="compareSelectStatus">
                <mat-option [value]="null">
                    Selecione
                </mat-option>
                <mat-option *ngFor="let status of statusPatientList" [value]="status">
                    {{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon (click)="removeFilter('statusPatient')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>

    <form [formGroup]="statusOrderCustonForm" *ngIf="statusOrderCustonForm">

        <mat-form-field fxFlex="100" appearance="outline">
            <mat-label>Status envio e download</mat-label>
            <mat-select formControlName="status">
                <mat-option [value]="null">
                    Selecione
                </mat-option>
                <mat-option *ngFor="let status of downloadAndSendStatus" [value]="status">
                    {{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon (click)="removeFilter('statusAuthorization')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
            matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>

    </form>

    <form [formGroup]="billingAllUnitsFilterForm" *ngIf="billingAllUnitsFilterForm" fxLayout="row" style="align-items: self-start">
        <mat-checkbox formControlName="billingAllUnits" color="primary">Faturar todas as unidades</mat-checkbox> <span>

            <mat-icon (click)="removeFilter('billingAllUnitsForm')" *ngIf="statusFilter === 'new'" style="cursor:pointer"
                matTooltip="Remover Filtro" color="warn" class='material-icons'>remove</mat-icon>
        </span>

    </form>

</div>