import { Firm, Performer, Person, Professional } from "../../backend-definitions.module";

export class ThirdParty {
    id: number;
    person: Person;
    professional: Professional;
    performer: Performer;
    firm: Firm;
}

export class ThirdPartyTO {
    id: number;
    professionalId: number;
    professionalName: string;
    performerId: number;
    performerName: string;
    personId: number;
    personName: string;
    firmId: number;
    firmName: string;
}

export class ThirdPartyParticipationTO {
     id: number;
     participationValue: number;
     participationType: string;
     procedureId: number;
     procedureName: string;
     thirdPartyId: number;
     performerId: number;
     performerName: string;
     personId: number;
     personName: string;
     firmId: number;
     firmName: string;
     professionalId: number;
     professionalName: string;
}

export class OrderItemThirdPartyTO {
    id: number;
    thirdPartyId: number;
    participationValue: number;
    participationType: string;
    procedureId: number;
    procedureName: string;
    performerId: number;
    performerName: string;
    personId: number;
    personName: string;
    firmId: number;
    firmName: string;
    professionalId: number;
    professionalName: string;
    value: number;
    orderItemId: number;
}
