import { EventEmitter, Output } from "@angular/core";
import { SnackBarService } from "./../../../../cw-utils/snackbar.service";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse, HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, map } from "rxjs";
import { PageOptions, ApiGateway, Performer, Patient } from "cw-backend";
import { CwUtils } from "cw-utils";
import { Environment } from "app/decorators/environment.decorator";
import CryptoJS from "crypto-js";

@Injectable()
export class MedicalProvider implements Resolve<any> {
    @Environment("apiKey") apiKey: string;
    @Environment("secretKey") secretKey: string;
    @Environment("urlMemed") urlMemed: string;
    static signatureProgress = new EventEmitter<any>();
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    document: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
        private http: HttpClient,
        private snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    static formView = new EventEmitter<boolean>();

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        throw new Error("Method not implemented.");
    }

    teleConsultaPost(dataTeleConsulta): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(
                    "https://us-central1-inu-ct-2209.cloudfunctions.net/sendEmail/",
                    dataTeleConsulta
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("document/").subscribe((res: HttpResponse<any>) => {
                //this.document = res.body;
                resolve(res);
            }, reject);
        });
    }

    saveDocument(key: any) {
        var obj = {
            c: "forcesave",
            key: key,
            userdata: "saving",
        };

        this.gw.post2("coauthoring/CommandService.ashx", obj).subscribe({
            next: (res: HttpResponse<any>) => {},
            error(error) {
                console.error(error);
            },
        });
    }

    getPage(
        pageOptions: PageOptions,
        patientId: number,
        type: number,
        performerId: number
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    `document/${patientId}/${type}/${performerId}/search`,
                    patientId,
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.document, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.document = res.body.content;
                        this.onDataChanged.next(this.document);
                    } else {
                        this.document = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageAttendance(
        pageOptions: PageOptions,
        patientId: number,
        type: number
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    `document/${patientId}/${type}/search`,
                    patientId,
                    pageOptions
                )
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.document, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.document = res.body.content;
                        this.onDataChanged.next(this.document);
                    } else {
                        this.document = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    insert(document): Promise<any> {
        if (document) {
            return new Promise((resolve, reject) => {
                this.gw
                    .post("document/", document)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                    }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw
                    .post("document/", document)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                    }, reject);
            });
        }
    }
    newDocument(document, solic? : any): Promise<any> {
        if (document) {
            return new Promise((resolve, reject) => {
                const url = `document/new-from-document-model?medico=${document.performer.id}&paciente=${document.patient.id}&sistema=1` + 
                            (solic ? `&solicitante=${solic}` : '');
                
                this.gw.post(url, document).subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    // this.snackBar.successMessage(response.body.message);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw.post(`new-from-document-model?medico=${document.performer.id}&paciente=${document.patient.id}&sistema=1`, document)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        // this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        }        
    }

    documentFromExpression(document): Promise<any> {
        if (document) {
            return new Promise((resolve, reject) => {
                this.gw
                    .post(
                        `document/expression-from-document-model?medico=${
                            document.performer.id
                        }&paciente=${document.patient.id}&sistema=${1}`,
                        document
                    )
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        //  this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        }
    }

    edit(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put(`document/closing/${id}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    //  this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    getById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`document/${id}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    getDocumentById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`document/document/${id}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    getTextByModel(
        modelId: number,
        patientId: number,
        performerId: number
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    `document/convertToText/${modelId}/${patientId}/${performerId}`
                )
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    getTextByModelAttendance(modelId: number, patientId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`document/convertToText/${modelId}/${patientId}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    delete(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete(`document/${id}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    generatePdf(document): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    `document/pdf/${document.id}?medico=${
                        document.performer.id
                    }&paciente=${document.patient.id}&sistema=${1}`
                )
                .subscribe({
                    next: (res: any) => {
                        resolve(res.body);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    signedPdf(document: any, performer: Performer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    `document/sign/${document.id}/${performer.professional.id}?medico=${
                        performer.id
                    }&paciente=${document.patient.id}&sistema=${1}`
                )
                .subscribe({
                    next: (res: any) => {
                        resolve(res.body);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    generateRequestPdf(
        documentId: number,
        performerId: number,
        patientId: number
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    `document/pdf/${documentId}?medico=${performerId}&paciente=${patientId}&sistema=${1}`
                )
                .subscribe({
                    next: (res: any) => {
                        resolve(res.body);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    generatePdfAnamnese(patientId: any, type: any, dateStart?: any, dateEnd?: any): Promise<any> {
        type = 4;
        return new Promise((resolve, reject) => {

            if (dateStart != null && dateEnd != null) {
                this.gw.get(`document/pdf/${patientId}/${type}?startDate=${dateStart}&startEnd=${dateEnd}`).subscribe({
                    next: (res: any) => {
                        resolve(res.body);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
            }else{
                this.gw.get(`document/pdf/${patientId}/${type}`).subscribe({
                    next: (res: any) => {
                        resolve(res.body);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
            }

            
        });
    }

    insertDocumentDiagnosis(documentDiagnosis): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("document/documentDiagnosis", documentDiagnosis)
                .subscribe({
                    next: (response: any) => {
                        this.snackBar.successMessage(response.body.message);
                        resolve(response);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    editDocumentDiagnosis(documentDiagnosis): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("document/documentDiagnosis/update", documentDiagnosis)
                .subscribe({
                    next: (response: any) => {
                        this.snackBar.successMessage(response.body.message);
                        resolve(response);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    deleteDocumentDiagnosis(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete(`document/documentDiagnosis/${id}`).subscribe({
                next: (response: any) => {
                    this.snackBar.successMessage(response.body.message);
                },
                error(error) {
                    console.error(error);
                },
            });
        });
    }

    getDocumentDiagnosis(documentId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`document/documentDiagnosis/${documentId}`).subscribe({
                next: (res: any) => {
                    resolve(res.body);
                },
                error(error) {
                    console.error(error);
                },
            });
        });
    }

    memedGetTokenPerformer(performer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `${this.urlMemed}sinapse-prescricao/usuarios/${performer}?api-key=${this.apiKey}&secret-key=${this.secretKey}`
                )
                .subscribe({
                    next: (res: HttpResponse<any[]>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    memedCreateTokenPerformer(performer): Promise<any> {
        //console.log("🚀 ~ file: performer.service.ts ~ line 114 ~ PerformerService ~ memedCreateTokenPerformer ~ performer", performer);
        const obj = {
            apiKey: this.apiKey,
            secretKey: this.secretKey,
            url: this.urlMemed,
        };
        performer.dadosToken = obj;
        return new Promise((resolve, reject) => {
            this.http
                .post(`https://memed-control.onrender.com/memed/`, performer)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        // console.log("🚀 ~ file: performer.service.ts ~ line 127 ~ PerformerService ~ returnnewPromise ~ res", res);
                        resolve(res);
                        //   console.log("🚀 ~ file: performer.service.ts ~ line 115 ~ PerformerService ~ returnnewPromise ~ res", res)
                    },
                    error(error) {
                        //console.log("🚀 ~ file: performer.service.ts ~ line 118 ~ PerformerService ~ error ~ error", error)
                        reject(error);
                    },
                });
        });
    }

    memedGetEspecialidade(idEspecialidade?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `https://api.memed.com.br/v1/especialidades/${idEspecialidade}`
                )
                .subscribe({
                    next: (res: HttpResponse<any[]>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    signatureAuth(auth): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`https://apihom.birdid.com.br/oauth`, auth)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    signature(data, token): Promise<any> {
        const headers = new HttpHeaders().set(
            "Authorization",
            "Bearer " + token
        );

        return new Promise((resolve, reject) => {
            this.http
                .post(
                    `https://cess.lab.vaultid.com.br/signature-service`,
                    data,
                    { headers }
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    signatureDocumentDownload(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `https://cess.lab.vaultid.com.br/file-transfer/fa2e3274-ad7f-8f20-88e6-9d4d12f83e88/0`
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error(error) {
                        reject(error);
                    },
                });
        });
    }

    loadPDFAsBase64(url: string) {
        return this.http.get(url, { responseType: "blob" }).pipe(
            map((response: Blob) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.onerror = reject;
                    reader.readAsDataURL(response);
                });
            }),
            map((dataUrlPromise: Promise<string>) => {
                return dataUrlPromise.then((dataUrl) => dataUrl.split(",")[1]);
            })
        );
    }

    signatureDocument(
        pdf: string,
        numberOTP: string,
        user: string,
        idDocument: number
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    `document/documentSignature/${user}/${numberOTP}/${idDocument}`,
                    pdf
                )
                .subscribe({
                    next: (response: any) => {
                        //this.snackBar.successMessage(response.body.message);
                        resolve(response);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    signatureDocumentBatch(
        numberOTP: string,
        user: string,
        documents: any[],
        performer: Performer,
        patient: Patient
    ): Promise<any> {
        let body = {
            user: user,
            otp: numberOTP,
            documents: documents,
        };

        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    `document/documentSignature/batch?medico=${
                        performer.id
                    }&paciente=${patient.id}&sistema=${1}`,
                    body
                )
                .subscribe({
                    next: (response: any) => {
                        //this.snackBar.successMessage(response.body.message);
                        resolve(response);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    generateUniqueRoomName(email) {
        const timestamp = new Date().getTime();
        const randomPart = Math.floor(Math.random() * 1000);
        const secretKey = "clinicall_web";
        const emailHash = CryptoJS.HmacSHA256(email, secretKey);
        return `room_${timestamp}_${randomPart}_${emailHash}`;
    }

    repeatDocument(requestId: number, professionalId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`document/repeatDocument/${requestId}/${professionalId}`)
                .subscribe({
                    next: (response: any) => {
                        this.snackBar.successMessage(response.body.message);
                        resolve(response);
                    },
                    error(error) {
                        console.error(error);
                    },
                });
        });
    }

    checkVariables(context: string, date: any, patientId: number, documentModelId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`document/checkVariables/${context}/${date}/${patientId}/${documentModelId}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

}
