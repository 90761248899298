import { DegreeParticipation } from '../tables/accreditation/degree-participation';

export class Auxiliary {
    id: number;
    code: string;
    name: string;
    percent: number;
    degreeParticipation: DegreeParticipation;
}

