// Default imports
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    AfterViewInit,
    Input,
} from "@angular/core";
import { Router } from "@angular/router";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { DocumentRef } from "@agm/core/lib/utils/browser-globals";
import * as $ from "jquery";

// Services
import {
    OrderTiss,
    OrderTissEditProvider,
    OrderTissListProvider,
    TypeGuideListProvider,
    TypeGuide,
    ServiceCharacterListProvider,
    ServiceCharacter,
    AccidentIndicatorListProvider,
    AccidentIndicator,
    AccommodationListProvider,
    Accommodation,
    TypeSurveyListProvider,
    TypeSurvey,
    TypeAttendanceListProvider,
    TypeAttendance,
    HospitalizationRegimeListProvider,
    HospitalizationRegime,
    TypeHospitalizationListProvider,
    TypeHospitalization,
    Order,
    DiseaseTimeUnit,
    DegreeParticipationListProvider,
    AttendanceListProvider,
} from "cw-backend";
import { CwUtils, SnackBarService } from "cw-utils";
import { RolePermissionService, HotKeyService } from "cw-services";
import { OccupationalHealth } from "../../../../../shared/cw-backend/models/tables/tiss/occupational-health";
import { OccupationalHealthListProvider } from "../../../../../shared/cw-backend/providers/tiss/occupational-health/occupational-health-list.provider";
import { AttendanceRegimeListProvider } from "../../../../../shared/cw-backend/providers/tiss/attendance-regime/attendance-regime-list.provider";
import moment from "moment";

@Component({
    selector: "order-tiss",
    templateUrl: "./order-tiss.component.html",
    styleUrls: ["./order-tiss.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class OrderTissComponent implements OnInit, AfterViewInit {
    @Input("order") order: Order;

    @ViewChild("orderTissEdit")
    orderTissEdit: DocumentRef;
    loading: boolean = true;
    progress: boolean = false;
    actionString: string = "";
    orderTiss: OrderTiss;
    orderTissForm: FormGroup;
    pageType: string;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    retrictionsFormFields: any;
    hasFormField: any[];
    typeGuideList: any[];
    serviceCharacterList: any[];
    accidentIndicatorList: any[];
    accommodationList: any[];
    typeSurveyList: any[];
    typeAttendanceList: any[];
    hospitalizationRegimeList: any[];
    typeHospitalizationList: any[];
    degreeParticipationList: any[];
    occupationalHealthList: any[];
    attendanceRegimeList: any[];
    specialCoverageList: any[];

    diseaseTimeUnitList: DiseaseTimeUnit[] = [
        { value: "A", description: "Ano" },
        { value: "M", description: "Mes" },
        { value: "D", description: "Dia" },
    ];

    defaultServiceCaracter = { id: 1 };
    defaultAccidentIndicator = { id: 4 };
    defaultTypeSurvey = { id: 3 };
    defaulthHospitalizationRegime = { id: 1 };
    defaultTypeHospitalization = { id: 1 };
    defaultAttendanceType = { id: 4 };

    private _unsubscribeAll: Subject<any>;

    constructor(
        private orderTissEditProvider: OrderTissEditProvider,
        private OrderTissListProvider: OrderTissListProvider,
        private typeGuideListProvider: TypeGuideListProvider,
        private serviceCharacterListProvider: ServiceCharacterListProvider,
        private accidentIndicatorListProvider: AccidentIndicatorListProvider,
        private accommodationListProvider: AccommodationListProvider,
        private attendanceListProvider: AttendanceListProvider,
        private typeSurveyListProvider: TypeSurveyListProvider,
        private typeAttendanceListProvider: TypeAttendanceListProvider,
        private occupationalHealthListProvider: OccupationalHealthListProvider,
        private attendanceRegimeListProvider: AttendanceRegimeListProvider,
        private hospitalizationRegimeListProvider: HospitalizationRegimeListProvider,
        private typeHospitalizationListProvider: TypeHospitalizationListProvider,
        private rolePermissionService: RolePermissionService,
        private degreeParticipationListProvider: DegreeParticipationListProvider,
        private snackBar: SnackBarService,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _router: Router
    ) {
        this.orderTiss = new OrderTiss();
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getList();
    }

    getList() {
        this.getByOrder();
        this.getTypeGuideList();
        this.getServiceCharacterList();
        this.getAccidentIndicatorList();
        this.getAccommodationList();
        this.getTypeSurveyList();
        this.getTypeAttendanceList();
        this.getHospitalizationRegimeList();
        this.getOccupationalHealthList();
        this.getAttendanceRegimeList();
        this.getTypeHospitalizationList();
        this.getDegreeParticipation();
        this.getSpecialCoverageList();
    }

    ngAfterViewInit() {
        // this.rolePermissionService.rolePermissions("operational-reception-order_tiss-edit").then(() => {
        //     setTimeout(() => {
        //         this.loading = false
        //     }, 300);
        // });

        this.rolePermissionService.formEvent.subscribe((formGroup) => {
            if (formGroup) {
                this.retrictionsFormFields = formGroup;
                formGroup.forEach((formField) => {
                    if (formField.restriction) {
                        this.orderTissForm.controls[formField.name].disable();
                    } else {
                        this.orderTissForm.controls[formField.name].enable();
                    }
                });
            }
        });

        $(document).keyup((event) => {
            if (
                this._router.url.startsWith(
                    "/operacional/recepcao/ordem-tiss/manter"
                )
            ) {
                HotKeyService.editKeyBiddings(
                    event,
                    "operational-reception-order-tiss-edit",
                    this.pageType
                );
            }
        });
    }

    ngOnDestroy(): void {
        // localStorage.setItem('last_modification', JSON.stringify(this.orderTissForm.getRawValue()));
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // Get all typeGuides
    getTypeGuideList() {
        this.typeGuideListProvider.getAll().then((res) => {
            this.typeGuideList = res.body;
        });
    }

    // Get all serviceCharacters
    getServiceCharacterList() {
        this.serviceCharacterListProvider.getAll().then((res) => {
            this.serviceCharacterList = res.body;
        });
    }

    // Get all accidentIndicators
    getAccidentIndicatorList() {
        this.accidentIndicatorListProvider.getAll().then((res) => {
            this.accidentIndicatorList = res.body;
        });
    }

    // Get all accommodations
    getAccommodationList() {
        this.accommodationListProvider.getAll().then((res) => {
            this.accommodationList = res.body;
        });
    }

    // Get all typeSurveys
    getTypeSurveyList() {
        this.typeSurveyListProvider.getAll().then((res) => {
            this.typeSurveyList = res.body;
        });
    }

    // Get all typeAttendances
    getTypeAttendanceList() {
        this.typeAttendanceListProvider.getAll().then((res) => {
            this.typeAttendanceList = res.body;
        });
    }

    // Get all occupationalHealth
    getOccupationalHealthList() {
        this.occupationalHealthListProvider.getAll().then((res) => {
            this.occupationalHealthList = res.body;
        });
    }

    // Get all attendanceRegime
    getAttendanceRegimeList() {
        this.attendanceRegimeListProvider.getAll().then((res) => {
            this.attendanceRegimeList = res.body;
        });
    }

    // Get all hospitalizationRegimes
    getHospitalizationRegimeList() {
        this.hospitalizationRegimeListProvider.getAll().then((res) => {
            this.hospitalizationRegimeList = res.body;
        });
    }

    // Get all typeHospitalizations
    getTypeHospitalizationList() {
        this.typeHospitalizationListProvider.getAll().then((res) => {
            this.typeHospitalizationList = res.body;
            this.setDefaultValues();
        });
    }

    // Get all degreeParticipationListProvider
    getDegreeParticipation() {
        this.degreeParticipationListProvider.getAll().then((res) => {
            this.degreeParticipationList = res.body;
            this.setDefaultValues();
        });
    }

    getSpecialCoverageList(){
        this.attendanceListProvider.getSpecialCovagereList().then((res) => {
            this.specialCoverageList = res.body;
            this.setDefaultValues();
        });
    }

    // Compare object in select
    compareSelect(o1: any, o2: any): boolean {
        if (!o1 || !o2) {
            return false;
        }
        return o1.id === o2.id;
    }

    compareEnum(o1: any, o2: any): boolean {
        if (o1 == null || o2 == null) {
            return null;
        }
        return o1 === o2;
    }

    // Reset form values
    reset() {
        if (this.pageType === "novo") {
            this.orderTissForm.reset();
        } else {
            this.orderTissForm = this.createOrderTissForm();
        }
    }

    createOrderTissForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.orderTiss.id],

            typeGuide: new FormControl(this.orderTiss.typeGuide, [
                Validators.required,
            ]),
            serviceCharacter: new FormControl(this.orderTiss.serviceCharacter),
            accidentIndicator: new FormControl(
                this.orderTiss.accidentIndicator
            ),
            accommodation: new FormControl(this.orderTiss.accommodation),
            typeSurvey: new FormControl(this.orderTiss.typeSurvey),
            typeAttendance: new FormControl(this.orderTiss.typeAttendance),
            hospitalizationRegime: new FormControl(
                this.orderTiss.hospitalizationRegime
            ),
            typeHospitalization: new FormControl(
                this.orderTiss.typeHospitalization
            ),
            dobbyNumber: new FormControl(this.orderTiss.dobbyNumber, [
                Validators.maxLength(20),
            ]),
            degreeParticipation: new FormControl(
                this.orderTiss.degreeParticipation
            ),
            requestDate: new FormControl(this.orderTiss.requestDate),

            requestTime: new FormControl(this.orderTiss.requestTime),

            billingStartDate: new FormControl(this.orderTiss.billingStartDate),

            billingEndDate: new FormControl(this.orderTiss.billingEndDate),

            diseaseTime: new FormControl(this.orderTiss.diseaseTime, [
                Validators.pattern("^[0-9]*$"),
            ]),
            operatorGuideNumber: new FormControl(
                this.orderTiss.operatorGuideNumber
            ),
            principalGuideNumber: new FormControl(
                this.orderTiss.principalGuideNumber
            ),

            diseaseTimeUnit: new FormControl(this.orderTiss.diseaseTimeUnit),
            certificate_alive: new FormControl(
                this.orderTiss.certificate_alive
            ),
            certificate_death: new FormControl(
                this.orderTiss.certificate_alive
            ),
            occupationalHealth: new FormControl(
                this.orderTiss.occupationalHealth
            ),
            attendanceRegime: new FormControl(this.orderTiss.attendanceRegime),
            specialCoverage: new FormControl(this.orderTiss.specialCoverage),
            certificate_death_newborn: new FormControl(
                this.orderTiss.certificate_death_newborn || false
            ),
            billing_type: new FormControl(this.orderTiss.billing_type),
            notes: new FormControl(this.orderTiss.notes),
            newBorn: new FormControl(this.orderTiss.newBorn || false, [
                Validators.required,
            ]),
            indication: new FormControl(this.orderTiss.indication),
        });
    }

    setDefaultValues() {
        if (!this.orderTiss.id) {
            this.orderTissForm.controls["serviceCharacter"].setValue(
                this.defaultServiceCaracter
            );
            this.orderTissForm.controls["accidentIndicator"].setValue(
                this.defaultAccidentIndicator
            );
            this.orderTissForm.controls["typeSurvey"].setValue(
                this.defaultTypeSurvey
            );
            this.orderTissForm.controls["typeAttendance"].setValue(
                this.defaultAttendanceType
            );
            this.orderTissForm.controls["hospitalizationRegime"].setValue(
                this.defaulthHospitalizationRegime
            );
            this.orderTissForm.controls["typeHospitalization"].setValue(
                this.defaultTypeHospitalization
            );
        }
    }

    getByOrder() {
        this.OrderTissListProvider.get(this.order.id).subscribe((res: any) => {
            this.orderTiss = res.body;
            this.orderTissForm = this.createOrderTissForm();
        });
    }

    async insert(action?): Promise<any> {
        this.actionString = "Salvando";
        this.progress = true;
        if (this.orderTiss.id) {
            this.edit();
            this.progress = false;
            return false;
        }
        let sucess: boolean = false;
        if (this.orderTissForm.invalid) {
            this.orderTissForm.markAllAsTouched();
            this.snackBar.warnMessage("Verifique os campos em destaques!");
            this.progress = false;
            return;
        }
        let data = this.orderTissForm.getRawValue();
        (data.requestDate =
            typeof data.requestDate !== "string" && data.requestDate != null
                ? CwUtils.formatDate(CwUtils.momentToDate(data.requestDate))
                : data.requestDate),
            (data.order = { id: this.order.id });
        if (!CwUtils.checkStringIsNull(data.billingStartDate)) {
            data.billingStartDate = CwUtils.formatDate(
                CwUtils.momentToDate(data.billingStartDate)
            );
        }
        if (!CwUtils.checkStringIsNull(data.billingEndDate)) {
            data.billingEndDate = CwUtils.formatDate(
                CwUtils.momentToDate(data.billingEndDate)
            );
        }
        await this.orderTissEditProvider
            .insert(data)
            .then((res) => {
                this.orderTiss.id = res.body.data.id;
            })
            .catch((err) => {
                this.progress = false;
            });
        this.progress = false;
    }

    async edit(action?): Promise<any> {
        const data = this.orderTissForm.getRawValue();
        (data.requestDate =
            typeof data.requestDate !== "string" && data.requestDate != null
                ? CwUtils.formatDate(CwUtils.momentToDate(data.requestDate))
                : data.requestDate),
            (data.order = { id: this.order.id });

        if (data.billingStartDate != null) {
            data.billingStartDate = moment(data.billingStartDate).format("YYYY-MM-DD");
        }
        if (data.billingEndDate != null) {
            data.billingEndDate = moment(data.billingEndDate).format("YYYY-MM-DD");
        }
        data.id = this.orderTiss.id;
        await this.orderTissEditProvider.edit(data).then((res) => {
            // Deactivate the cancel button
            this.orderTissForm.markAsPristine();
        });
        this.progress = false;
    }

    delete(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
            }
        );

        this.confirmDialogRef.componentInstance.action = "delete";

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.orderTissEditProvider
                    .delete(this.orderTiss)
                    .then((res) => {
                        this._router.navigate([
                            "operacional/recepcao/ordem-tiss",
                        ]);
                    });
            }
        });
    }

    modifiedRestrictionFields(): boolean {
        let isModify = false;
        if (this.retrictionsFormFields) {
            this.retrictionsFormFields.forEach((formName) => {
                if (formName.restriction == true) {
                    if (!this.orderTissForm.controls[formName.name].pristine) {
                        this.orderTissForm.setValue[formName.name] =
                            this.orderTiss + formName.name;
                        this.orderTissForm.controls[formName.name].disable();
                        this.snackBar.errorMessage(
                            "Você não possui permissão para alterar determinados campos"
                        );
                        isModify = true;
                    }
                }
            });
            return isModify;
        }
    }

    checkValid(): boolean {
        if (this.orderTissForm.invalid) {
            const controls = this.orderTissForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    if (!controls[name].value) {
                        this.orderTissForm
                            .get(name)
                            .setErrors({ required: true });
                    } else {
                        this.orderTissForm
                            .get(name)
                            .setErrors({ incorrect: true });
                    }
                    this.orderTissForm.get(name).markAsTouched();
                }
            }
            this.snackBar.errorMessage("Verifique o(s) campo(s) em destaque");
            return false;
        } else {
            return true;
        }
    }
}
