import { Injectable, Output, EventEmitter } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse, HttpHeaders, HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Horary, Performer } from "../../../models/backend-definitions.module";
import { ApiGateway, PageOptions } from "cw-backend";
import { CwUtils } from "cw-utils";
import { reject } from "lodash";
import { ApiGatewayOptions } from '../../../api-gateway';
import { QuotaLackTO } from "../../../models/attendance/QuotaLack";
import { OrderItem } from "app/main/modules/operational/reception/os/components/os-items/os-items.component";

@Injectable()
export class AttendanceListProvider implements Resolve<any> {
    horary: Horary[];
    attendance: any[];
    orders: [];
    ordersItens: [];
    performer: Performer[];
    attendanceEdit: any;
    onDataChanged: BehaviorSubject<any>;
    onHoraryChanged: BehaviorSubject<any>;
    onTypeChanged: BehaviorSubject<any>;
    onPerformerChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location, private http: HttpClient) {
        this.onDataChanged = new BehaviorSubject({});
        this.onPerformerChanged = new BehaviorSubject({});
        this.onTypeChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.attendanceEdit = this.state.currentAttendance;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "person.name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions, "I")]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }
        return this.gw.get("attendance/:id", { id: id });
    }

    getById(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }
        return this.gw.get("attendance/wAll/:id", { id: id });
    }

    getOrderTiss(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }
        return this.gw.get("order/tiss/:id", { id: id });
    }

    checkProductAndFee(id: number): Observable<HttpResponse<Boolean>> {
        if (!id) {
            return null;
        }
        return this.gw.get("order/checkProduct/:id", { id: id });
    }

    getOrderItemListByOrder(orderId){
        return new Promise<any>((resolve, reject) => {
            this.gw
                .get(`order/orderItem/historic/${orderId}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }

                });
        });
    }

    getPage(pageOptions: PageOptions, type, refresh?,): Promise<any> {
        if (!type) {
            type = "I";
        }
        return new Promise((resolve, reject) => {
            this.gw
                .post("attendance/search/", { type: type }, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            refresh ||
                            (!CwUtils.compare(this.attendance, res.body.content) &&
                                res.body.content.length > 0)
                        ) {
                            this.attendance = res.body.content;
                            this.onDataChanged.next(this.attendance);
                        } else {
                            this.attendance = res.body.content;
                        }
                        resolve(res);
                    }, error: (err) => { reject(err) }

                });
        });
    }

    getPageOrders(pageOptions: PageOptions, renew?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/search", pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            (!CwUtils.compare(this.orders, res.body.content) &&
                                res.body.content.length > 0) ||
                            renew
                        ) {
                            this.orders = res.body.content;
                            this.onDataChanged.next(this.orders);
                        } else {
                            this.orders = res.body.content;
                        }

                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getPageOrdersByPatientId(patientId: number, pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/search/${patientId}`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            (!CwUtils.compare(this.orders, res.body.content) &&
                                res.body.content.length > 0)
                        ) {
                            this.orders = res.body.content;
                        } else {
                            this.orders = res.body.content;
                        }
                        this.onDataChanged.next(this.orders);
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getPageOrdersItems(pageOptions: PageOptions, id: any, renew?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`orderItems/search/${id}`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            (!CwUtils.compare(this.orders, res.body.content) &&
                                res.body.content.length > 0) ||
                            renew
                        ) {
                            this.ordersItens = res.body.content;
                            this.onDataChanged.next(this.ordersItens);
                        } else {
                            this.ordersItens = res.body.content;
                        }

                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSimplePage(pageOptions: PageOptions, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("attendance/search/", { type: type }, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getInsurance(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("attendance/insurance/search", pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSpecialCovagereList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("order/special-coverage")
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }


    getProcedureByInsurance(
        insuranceId: number,
        performerId: any,
        companyId: any,
        pageOptions: PageOptions,
    ): Promise<any> {
        const body = {
            insuranceId: insuranceId,
            performerId: performerId || "",
            companyId: companyId,
            pageSearchTO: pageOptions,
        }
        return new Promise((resolve, reject) => {
            this.gw
                .post(`attendance/procedure/searchByFilter/`, body)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getPerformer(
        pageOptions: PageOptions,
        insuranceId: number,
        procedureId?: any
    ): Promise<any> {
        const body = {
            insuranceId: insuranceId,
            procedureId: procedureId || "",
            pageSearchTO: pageOptions,
        };
        return new Promise((resolve, reject) => {
            this.gw
                .post(`attendance/performer/search`, body)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSpeciality(professionalId, pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    `attendance/speciality/search/${professionalId}`,
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getProfessional(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`attendance/professional/search`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getOrders(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/search`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getOrderById(id) {
        return new Promise<any>((resolve, reject) => {
            this.gw
                .get(`order/${id}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getOrderItemById(id) {
        return new Promise<OrderItem>((resolve, reject) => {
            this.gw
                .get(`order/orderItem/${id}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res.body);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    search(filter, entity = "attendance"): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(entity + "/search", filter)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    checkLack(lack:QuotaLackTO){
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/checkLack`, lack)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getCids(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("cid/search", pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    findByOrderAuthorizerStatus(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/search/authorizer", pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    sendByEmailOrDownload(pageOptions: any, type:string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/send/email-download/type/${type}`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getExameTypeList() {
        return new Promise<any>((resolve, reject) => {
            this.gw
                .get("attendance/exameType/")
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getMatMed(procedureId: number, insuranceId: number, companyId: number) {
        return new Promise((resolve, reject) => {
            this.gw.get(`attendance/accreditation/kit/procedure/${procedureId}/insurance/${insuranceId}/company/${companyId}`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getViaAccess() {
        return new Promise((resolve, reject) => {
            this.gw.get(`viaAccess/`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getViaAdministration() {
        return new Promise((resolve, reject) => {
            this.gw.get(`viaAdministration/`)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }


    getDefaultValues(patientId) {
        return new Promise((resolve, reject) => {
            this.gw.get(`order/new/${patientId}`)
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSesionByPatientId(patientId, realized) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`order/session/:patientId/:realized`, { patientId: patientId, realized: realized })
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getOrderItemByPatientIdAndSessionId(patientId, sessionId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`order/order-item/session/:patientId/:sessionId`, { patientId: patientId, sessionId: sessionId })
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSessionItemBySessionId(sessionId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`order/session-item/realized/:sessionId`, { sessionId: sessionId })
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getinfoOrderBilling(orderId: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`order/info-billing/:orderId`, { orderId: orderId })
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                })
        })
    }

    getMemoryCalc(orderId: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`order/memoryCalc/:orderId`, { orderId: orderId })
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    }, error: (err) => { reject(err) }
                })
        })
    }


}
