import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderProvider {

  private currentLoadingPercent:number = 10;


  private loadingRunning = new Subject<boolean>();
  public  loadingRunning$: Observable<boolean>;


  private loadingProgress = new Subject<number>();
  loadingProgress$: Observable<number>;


  constructor(/*public http: Http*/) {
    this.loadingRunning$ = this.loadingRunning.asObservable();
    this.loadingProgress$ = this.loadingProgress.asObservable();
  }

  loadingItems:any = [];

  private interval:any;
  

  show() {

    if(this.interval){
      clearInterval(this.interval);
    }

    let this1 = this;

    this.currentLoadingPercent = 10;

    this.interval = setInterval(function(){
      this1.loadingProgress.next(this1.currentLoadingPercent += this1.getRamdom(1,10));
    },500);

    this.loadingRunning.next(true);

    // if(this.loadingItems.length > 0){
    //   return;
    // }

    // let loading = this.loadingCtrl.create({
    //   content: "Aguarde..."
    // });

    // loading.present().catch(() => {});

    // this.loadingItems.push(loading);
  }

  hide() {
      
    this.currentLoadingPercent = 100;
    this.loadingRunning.next(false);
    clearInterval(this.interval);

  }

  getRamdom(min, max) {
    return Math.random() * (max - min) + min;
}


}