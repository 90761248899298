
import { NgModule } from '@angular/core';

import { ThirdPartyEditProvider } from './thirdparty-edit.provider';
import { ThirdPartyListProvider } from './thirdparty-list.provider';

export * from './thirdparty-edit.provider';
export * from './thirdparty-list.provider';

export {
  ThirdPartyListProvider,
  ThirdPartyEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    ThirdPartyEditProvider,
    ThirdPartyListProvider
  ]
})
export class ThirdPartyBackendModule { }
