import { HttpResponse, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { Procedure, ApiGateway, Insurance } from "cw-backend";
import { SnackBarService } from "cw-utils";
import { Environment } from "app/decorators/environment.decorator";

@Injectable()
export class InsuranceEditProvider implements Resolve<any> {
    @Environment("baseUrl") baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    insurance: Insurance;
    countryValue: any;
    country = { id: "" };

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.getCountry();
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getInsurance()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getInsurance(): Promise<any> {
        return new Promise((resolve, reject) => {
            let insurance = JSON.parse(localStorage.getItem("accreditation"));

            if (insurance) {
                this.insurance = insurance;
                this.onDataChanged.next(this.insurance);
                resolve(insurance);
            } else if (this.routeParams.id === "novo") {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw
                    .get("insurance/wAll/" + this.routeParams.id)
                    .subscribe((insurance: Insurance) => {
                        this.insurance = insurance;
                        this.onDataChanged.next(this.insurance);
                        resolve(insurance);
                    }, reject);
            }
        });
    }

    insert(insurance): Promise<any> {
        this.country.id = this.countryValue;

        if (insurance.address) {
            insurance.addressList.forEach((element) => {
                element.country = this.country;
            });
        }

        if (
            insurance.address &&
            insurance.address.country != null &&
            this.country.id != ""
        ) {
            return new Promise((resolve, reject) => {
                this.gw
                    .post("insurance/", insurance)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw
                    .post("insurance/", insurance)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        }
    }

    edit(insurance): Promise<any> {
        this.country.id = this.countryValue;

        if (insurance.addressList) {
            insurance.addressList.forEach((element) => {
                element.country = this.country;
            });
        }

        if (
            insurance.address &&
            insurance.address.country != null &&
            this.country.id != ""
        ) {
            return new Promise((resolve, reject) => {
                this.gw
                    .put("insurance/", insurance)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.gw
                    .put("insurance/", insurance)
                    .subscribe((response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, reject);
            });
        }
    }

    insertDiscountRule(discountRule) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post("insurance/discount-rule", discountRule).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    insertPlan(insurancePLan) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post("insurance/plan", insurancePLan).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    insertInsurancePlan(insuranceId, planId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`insurance/${insuranceId}/plan/${planId}/insurance-plan/insert`,).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }


    getInsuranceDiscountByInsuranceId(insuranceId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`insurance/discount-rule/${insuranceId}`).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getCountry() {
        this.getParameterCountry().then((value) => {
            value.forEach((country) => {
                if (country.group == "Gerais") {
                    country.parameterList.forEach((parameter) => {
                        if (parameter.id == 28) {
                            this.countryValue = parameter.value;
                        }
                    });
                }
            });
        });
    }

    getParameterCountry(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("parameter/context/SYSTEM").subscribe({
                next: (res: HttpResponse<any[]>) => {
                    res.body ? resolve(res.body) : resolve(res);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    deletePhoto(insuranceId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("insurance/:insuranceId/image", {
                    insuranceId: insuranceId,
                })
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    delete(insurance): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete("insurance/" + insurance.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    deleteInsurancePlan(insurancePlanId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete(`insurance/insurance-plan/${insurancePlanId}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    },error(err) {
                        reject(err);
                    },
                });
        });
    }

    uploadImage(file: File, insuranceId): Promise<any> {
        return new Promise((resolve, reject) => {
            const endpoint =
                this.baseUrl + "insurance/uploadLogo/" + insuranceId;
            const formData: FormData = new FormData();
            formData.append("file", file, file.name);

            let authToken = localStorage.getItem("token");
            let headers = new HttpHeaders();
            headers = headers.append("X-Auth-Token", authToken);

            this.httpClient
                .post(endpoint, formData, { headers: headers })
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        });
    }
}
