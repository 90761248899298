import { HttpResponse, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway } from "cw-backend";

import { SnackBarService } from "cw-utils";
import { StandardTable } from "../../../models/accreditation/standard-table";
import { Environment } from "app/decorators/environment.decorator";

@Injectable()
export class StandardTableEditProvider implements Resolve<any> {
    @Environment("baseUrl") baseUrl: string;
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    refreshChanged: BehaviorSubject<any>;
    standardTable: StandardTable;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        private httpClient: HttpClient
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.refreshChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getStandardTable()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getStandardTable(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === "novo") {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw
                    .get("standardTable/wAll/" + this.routeParams.id)
                    .subscribe((res: StandardTable) => {
                        this.standardTable = res;
                        this.onDataChanged.next(this.standardTable);
                        resolve(res);
                    }, reject);
            }
        });
    }

    insert(standardTable): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("standardTable/", standardTable)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    edit(standardTable): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("standardTable/", standardTable)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    delete(standardTable): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("standardTable/" + standardTable.id)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    insertItem(standardTableItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("standardTableItem/", standardTableItem)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    editItem(standardTableItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("standardTableItem/", standardTableItem)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    deleteItem(standardTable): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("standardTableItem/" + standardTable.id)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    upload(file: File, standardTableId): Promise<any> {
        return new Promise((resolve, reject) => {
            const endpoint =
                this.baseUrl +
                "standardTable/uploadFileStandardTable/" +
                standardTableId;
            const formData: FormData = new FormData();
            formData.append("file", file, file.name);

            let authToken = localStorage.getItem("token");
            let headers = new HttpHeaders();
            headers = headers.append("X-Auth-Token", authToken);

            this.httpClient
                .post(endpoint, formData, { headers: headers })
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        });
    }

    importFile(
        file: File,
        standardTableId: number,
        code: string,
        delimiter: string
    ) {
        return new Promise<any>((resolve, reject) => {
            const endpoint =
                this.baseUrl +
                `standardTable/uploadFile/${standardTableId}/${code}/${delimiter}`;
            const formData: FormData = new FormData();
            formData.append("file", file, file.name);

            let authToken = localStorage.getItem("token");
            let headers = new HttpHeaders();
            headers = headers.append("X-Auth-Token", authToken);

            this.httpClient
                .post(endpoint, formData, { headers: headers })
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }
}
