import { CwCompare } from "./../../../../cw-utils/compare";

import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { PageOptions } from "../../../util/backend-util-module";
import { ApiGateway } from "../../../api-gateway";
import { Product } from "../../../models/backend-definitions.module";
import { SnackBarService } from "../../../../cw-utils/snackbar.service";
import { CwUtils } from "cw-utils";

@Injectable()
export class ProductListProvider implements Resolve<any> {
    product: Product[];
    productEdit: Product;
    productType: string;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        let productType = state.url.split("/")[3];
        switch (productType) {
            case "material":
                productType = "M";
                break;
            case "opme":
                productType = "O";
                break;
            case "medicamento":
                productType = "D";
                break;
        }
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.productEdit = this.state.currentProduct;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions, productType)]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<Product> {
        if (!id) {
            return null;
        }
        return this.gw.get("product/:id", { id: id });
    }

    get currentProduct(): Product {
        return this.productEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("product/").subscribe((res: HttpResponse<any>) => {
                this.product = res.body;
                this.onDataChanged.next(this.product);
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions, productType): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("product/search/" + productType, pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.product, res.body.content)) {
                        this.product = res.body.content;
                        this.onDataChanged.next(this.product);
                    } else {
                        this.product = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageDef(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("def/search/", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.product, res.body.content)) {
                        this.product = res.body.content;
                        this.onDataChanged.next(this.product);
                    } else {
                        this.product = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageProductDef(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("product/searchDef/D", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (!CwUtils.compare(this.product, res.body.content)) {
                        this.product = res.body.content;
                        this.onDataChanged.next(this.product);
                    } else {
                        this.product = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    insertTableStandart(accreditation) {
        return new Promise((resolve, reject) => {
            this.gw.post("product/tableStandart/", accreditation).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err: HttpResponse<any>) => {
                    reject(err);
                },
            });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
