
import { NgModule } from '@angular/core';

import { HistoricEditProvider } from './historic-edit.provider';
import { HistoricListProvider } from './historic-list.provider';

export * from './historic-edit.provider';
export * from './historic-list.provider';

export {
  HistoricListProvider,
  HistoricEditProvider
};

@NgModule({
  declarations: [

  ],
  imports: [
  ],
  exports: [

  ],
  providers: [
    HistoricEditProvider,
    HistoricListProvider
  ]
})
export class HistoricBackendModule { }
