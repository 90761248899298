import { Currency, MeasureTiss } from 'cw-backend';
import { Accreditation } from './accreditation';

export class FeeCode {
    id: number;
    code: string;
    tableName: string;
    fee: Fee;
}

export class Fee {
    id: number;
    name: string;
    type: string;
    active: boolean;
    accreditationList: Accreditation[];
    feeCodeList: FeeCode[];
    measureTiss: MeasureTiss;
    currency:Currency;
    value:number;
}