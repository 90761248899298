import { Currency } from './currency';
import { Fee } from './fee';
import { Procedure, Kit } from './procedure';
import { TableType } from '../tables/accreditation/table-type';
import { Agreement } from './agreement';
import { Port } from './port';
import { Product } from './product';
import { Auxiliary, DegreeParticipation } from 'cw-backend';

export class Accreditation {
    id: number;
    name: string;
    alias: string;
    code: string;
    codeAuth: string;
    active: boolean;
    value: number;
    agreement: Agreement;
    currency: Currency;
    fee: Fee;
    procedure: Procedure;
    product: Product;
    auxiliary: Auxiliary;
    tableType: TableType;
    kitList: Kit[];
    accreditationSpec: AccreditationSpec;
    constructor() {
        // TODO - Rever a necessidade de estanciar esses objetos
        this.accreditationSpec = new AccreditationSpec();
        // this.currency = new Currency();
        this.agreement = new Agreement();
        this.kitList = new Array<Kit>();
    }
}

export class AccreditationSpec {
    id: number;
    uco: number;
    quota: number;
    lack: number;
    sadtValue: number;
    shValue: number;
    value: number;
    coParticipationValue: number;
    coParticipationType: string;
    film: number;
    accreditation: Accreditation;
    currency: Currency;
    degreeParticipation: DegreeParticipation;
    port: Port;
    require_autorization: boolean;
    constructor() {
        this.currency = new Currency();
        this.port = new Port();

    }
}

export class AccreditationCopy {
    nome: string;
    copy: boolean;

}