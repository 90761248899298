import { Kit } from './procedure';
import { Accreditation } from './accreditation';
import { Insurance } from './insurance';
import { Moment } from 'moment';
import { Company } from '../register/organizational/company';
import { Firm } from '../backend-definitions.module';

export class Agreement {
    id: number;
    active?: boolean;
    validity?: Moment;
    startDate?: Moment;
    endDate?: Moment;
    file?: File;
    company?: Company;
    hiredCompany?: Company;
    kitList?: Kit[];
    insurance?: Insurance;
    accreditationList?: Accreditation[];
    agreementSpec?: AgreementSpec;
    constructor() {
        this.insurance = new Insurance();
    }
}

export class AgreementSpec {
    id: number;
    agreement: Agreement
    firm: Firm
    guideNumber: String
    sadtNumber: String
}