
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'cw-backend';
import { SnackBarService } from 'cw-utils';
import { Transfer } from '../../../models/operational/transfer/transfer';
import { reject } from 'lodash';

@Injectable()
export class TransferEditProvider implements Resolve<any>
{
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    transfer: Transfer;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService
    ) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getTransfer()]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getTransfer(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = this.checkLastModifications()
            if (data && data.id == this.routeParams.id) {
                this.transfer = data;
                this.onDataChanged.next(this.transfer);
                resolve(this.transfer);
            } else if (this.routeParams.id === 'novo') {
                this.onDataChanged.next(false);
                resolve(false);
            }
            else {
                this.gw.get('transfer/' + this.routeParams.id).subscribe({
                    next: (res: Transfer) => {
                        this.transfer = res;
                        this.onDataChanged.next(this.transfer);
                        resolve(res);
                    },
                    error: (err: any) => { reject(err); },
                });
            }
        });
    }

    getProfitDiscount(profitDiscountId){
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`transfer/profit-discount/${profitDiscountId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                }, error: (err: any) => { reject(err); },
            });
        });   
    }

    insert(transfer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/', transfer).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); },
            });
        });
    }

    insertTransfer(transferProfitDiscount): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/profit-discount/insert', transferProfitDiscount).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); },
            });
        });
    }

    edit(transfer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/', transfer).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }

    duplicateProfitDiscount(sourceId, targetId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`transfer/profit-discount/duplicate/${sourceId}/${targetId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }


    transferItems(tranferMaster): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('transfer/items', tranferMaster).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); }
            });
        });
    }

    delete(transfer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('transfer/' + transfer.id).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); },
            });
        });
    }

    deleteProfitDiscount(profitDiscountId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete(`transfer/profit-discount/${profitDiscountId}`).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, error: (err: any) => { reject(err); },
            });
        });
    }


    closedTransfer(transfer: Transfer) {
        return new Promise((resolver, reject) => {
            this.gw.put('transfer/closed', transfer).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolver(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (err: any) => { reject(err); }
            })
        })
    }

    checkLastModifications(): Transfer {
        let wasLocked = JSON.parse(localStorage.getItem('session_locked'));
        let data;
        if (wasLocked) {
            data = JSON.parse(localStorage.getItem('last_modification'));
            localStorage.removeItem('session_locked');
        }
        return data;
    }
}
