import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Moment, isMoment } from "moment";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway } from "../cw-backend/api-gateway";
import { HttpResponse } from "@angular/common/http";

@Injectable()
export class CwUtils {
    private onlineStatus: BehaviorSubject<boolean>;

    constructor(public gw: ApiGateway) {
        this.onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);
        window.addEventListener("online", () => this.updateOnlineStatus());
        window.addEventListener("offline", () => this.updateOnlineStatus());

        
    }

    public static checkDate(date) {
        if (date instanceof Date) {
            return this.formatDate(date);
        } else return date;
    }

    public static momentToDate(moment) {
        if (isMoment(moment)) {
            return moment.toDate();
        }
    }

    public static dateConverterToLocalDate(accessDate: Moment, hours?): any {
        if (accessDate.isValid && !hours) {
            return accessDate.format("YYYY-MM-DD");
        } else if (accessDate.isValid && hours) {
            accessDate.set({
                hours: hours[0] + hours[1],
                minutes: hours[3] + hours[4],
            });
            return accessDate.format("YYYY-MM-DDTHH:mm");
        } else {
            return accessDate;
        }
    }

    public static dateConverterToTimestamp(accessDate: Moment, hours?): any {
        if (accessDate.isValid) {
            return accessDate.format("x");
        } else {
            return accessDate;
        }
    }

    public static dateConverter(accessDate, hours?): any {
        let date: Date = new Date();
        let data = date.setFullYear(
            accessDate.year,
            accessDate.monthValue - 1,
            accessDate.dayOfMonth
        );
        if (hours) {
            date.setHours(
                accessDate.hour,
                accessDate.minute,
                accessDate.second
            );
        }
        return date;
    }

    public static dateArrayConverter(accessDate, hour?): any {
        let date: Date;
        date = new Date();
        let month: any = accessDate[1];
        if (month < 10) {
            month = "0" + month;
        }
        date.setFullYear(accessDate[0], month - 1, accessDate[2]);
        if (hour) {
            date.setHours(accessDate[3], accessDate[4], 0o0);
        }
        return date;
    }

    // CONVERT string dd/mm/yyyy para date
    public static formatStringDate(date, campo = "Data"): Date {
        if (!date || typeof date != "string") {
            throw new Error(`Campo ${campo} com dados inválidos ou vazio.`);
        }

        const dateString = date;
        const [day, month, year] = dateString.split("/").map(Number);
        return date = new Date(year, month - 1, day); 
    }

    public static formatDate(date, campo = "Data"): string {
        if (!date || typeof date == "string") {
            throw new Error(`Campo ${campo} com dados inválidos ou vazio.`);
        }
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }

        return yyyy + "-" + mm + "-" + dd;
    }

    public static compare(arr1, arr2) {
        if (!arr1 || !arr2) return true;
        let result;
        arr1.forEach((e1, i) =>
            arr2.forEach((e2) => {
                if (e1.length > 1 && e2.length) {
                    result = this.compare(e1, e2);
                } else if (e1.id !== e2.id) {
                    result = false;
                } else {
                    result = true;
                }
            })
        );
        return result;
    }

    public static getPageOptions(matPaginator, matSort, filter): any {
        let pageOptions = new PageOptions();
        pageOptions.sizePage = matPaginator.pageSize;
        pageOptions.page = matPaginator.pageIndex;

        if (matSort.active && matSort.direction) {
            pageOptions.fieldSort = matSort.active;
            pageOptions.sortDirection = matSort.direction.toString();
        }
        if (filter) {
            pageOptions.argument = filter;
        }
        return pageOptions;
    }

    public static setMatPaginator(
        matPaginator: MatPaginator,
        pageOptions
    ): MatPaginator {
        matPaginator.pageIndex = pageOptions.page;
        matPaginator.pageSize = pageOptions.sizePage;
        return matPaginator;
    }

    public static setMatSort(matSort, pageOptions): MatSort {
        matSort.active = pageOptions.fieldSort;
        if (pageOptions.sortDirection == "asc") {
            matSort.direction = "asc";
        } else {
            matSort.direction = "desc";
        }
        return matSort;
    }

    public static comparePageOptions() {
        if (
            location.pathname
                .toString()
                .includes(sessionStorage.getItem("navigation"))
        ) {
            return false;
        } else {
            return true;
        }
    }

    public static checkPage(path) {
        if (location.pathname.includes(path) || !path || !location.pathname) {
            return true;
        }
        return false;
    }

    public static isObject(value: any): value is object {
        return value !== null && typeof value === 'object';
    }

    public static extractId(object: any) {
        if (!object?.id) {
            return null;
        }

        return { id: object.id };
    }

    public static extractOnlyId(object: any) {
        if (!object?.id) {
            return null;
        }

        return object.id ;
    }
    
    public static checkStringIsNull(str: string | null | undefined){
        return str !== null && str !== undefined && str.trim().length > 0;
    }

    private updateOnlineStatus() {
        this.onlineStatus.next(navigator.onLine);
    }

    getOnlineStatus(): Observable<boolean> {
        return this.onlineStatus.asObservable();
    }

   /* getLatency(): Promise<number> {
        const start = Date.now();
        return this.gw
            .get("util/ping")
            .toPromise()
            .then((response) => Date.now() - start)
            .catch(() => -1); // Retorna -1 em caso de erro na requisição
    }*/
}

export class PageOptions {
    argument: any = "";
    page: number = 0;
    sizePage: number = 25;
    fieldSort?: string = "name" || "";
    sortDirection: string = "asc";
}
