import { Speciality } from './../tables/accreditation/speciality';
import { Product } from './product';
import { Fee } from './fee';
import { Group, Currency, TableType } from 'cw-backend';
import { Accreditation } from './accreditation';
import { Quotation } from './quotation';
import { Insurance } from './insurance';
import { OccupationAccreditation } from './occupation';
import { Gender } from '../others/gender';
import { Auxiliary } from './auxiliary';

export class Procedure {
    id: number;
    alias: string;
    name: string;
    active: boolean;
    description: string;
    group: Group;
    procedureCodeList: ProcedureCode[];
    accreditationList: Accreditation[];
    procedureKitList: Kit[];
    procedureSpec: ProcedureSpecs;
    procedureOccupationList: ProcedureOccupation[];
    specialityList: Speciality[];
    quotationList: Quotation[];
    currency:Currency;
    reduced_code: string;
    value:number;
}

export class ProcedureOccupation {
    id: number;
    occupation: OccupationAccreditation;
    procedure: Procedure;
}

export class ProcedureSpeciality {
    id: number;
    procedure: Procedure;
    speciality: Speciality;
}

export class ProcedureSpecs {
    id: number;
    ageRage: string;
    ageMin: number;
    ageMax: number;
    ageMesuredIn: string;
    gender: Gender;
    preparation: string;
    // insurance: Insurance;
    procedure: Procedure;
    tableType: TableType;
}

export class ProcedureCode {
    id: number;
    code: string;
    tableName: string;
    procedure: Procedure;
}

export class Kit {
    id: number;
    value: number;
    quantity: number;
    accreditation: Accreditation;
    fee: Fee;
    product: Product;
    currency: Currency;
    procedure: Procedure;
    auxiliary: Auxiliary;
}

