import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { PageOptions, ApiGateway } from "cw-backend";
import {
    StandardTable,
    StandardTableItem,
} from "../../../models/accreditation/standard-table";
import { CwUtils } from "cw-utils";

@Injectable()
export class StandardTableListProvider implements Resolve<any> {
    standardTable: StandardTable[];
    standardTableEdit: StandardTable;
    standardTableItems: StandardTableItem[];
    onDataChanged: BehaviorSubject<any>;
    onItemsChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
        this.onItemsChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.standardTableEdit = this.state.currentStandardTable;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
            const standard = new StandardTable();
            if (
                !Object.getOwnPropertyNames(standard).includes(
                    this.pageOptions.fieldSort
                )
            ) {
                this.pageOptions.fieldSort = "name";
            }
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<HttpResponse<StandardTable>> {
        if (!id) {
            return null;
        }
        return this.gw.get("standardTable/:id", { id: id });
    }

    get currentStandardTable(): StandardTable {
        return this.standardTableEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("standardTable/")
                .subscribe((res: HttpResponse<any>) => {
                    this.standardTable = res.body;
                    this.onDataChanged.next(this.standardTable);
                    resolve(res);
                }, reject);
        });
    }

    getStandardTableByType(type: string,pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`standardTable/type/${type}/search`, pageOptions).subscribe({
                next: (res) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getTypeSimpleList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("standardTable/type/simpleList")
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("standardTable/search", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(
                            this.standardTable,
                            res.body.content
                        ) &&
                        res.body.content.length > 0
                    ) {
                        this.standardTable = res.body.content;
                        this.onDataChanged.next(this.standardTable);
                    } else {
                        this.standardTable = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getItemsByStandardTableId(
        standardTableId,
        pageOptions: PageOptions,
        renew?: boolean
    ): Promise<any> {
        if (pageOptions.sizePage == null) {
            pageOptions.sizePage = 5;
        }

        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "standardTableItem/search/standardTable/" + standardTableId,
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            (!CwUtils.compare(
                                this.standardTableItems,
                                res.body.content
                            ) &&
                                res.body.content.length > 0) ||
                            renew
                        ) {
                            this.standardTableItems = res.body.content;
                            this.onItemsChanged.next(this.standardTableItems);
                        } else {
                            this.standardTableItems = res.body.content;
                        }
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }
    getItemsByStandardTableIdRangeCode(
        standardTableId,
        codeMin,
        codeMax,
        pageOptions: PageOptions,
        renew?: boolean
    ): Promise<any> {
        if (pageOptions.sizePage == null) {
            pageOptions.sizePage = 5;
        }
        const range = {
            standardTableId: standardTableId,
            codeMin: codeMin,
            codeMax: codeMax,
        };
        return new Promise((resolve, reject) => {
            this.gw
                .post(
                    "standardTableItem/search/standardTable/:standardTableId/range-code/:codeMin/:codeMax",
                    range,
                    pageOptions
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        if (
                            (!CwUtils.compare(
                                this.standardTableItems,
                                res.body.content
                            ) &&
                                res.body.content.length > 0) ||
                            renew
                        ) {
                            this.standardTableItems = res.body.content;
                            this.onItemsChanged.next(this.standardTableItems);
                        } else {
                            this.standardTableItems = res.body.content;
                        }
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
